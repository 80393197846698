import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { BaseCSS, GridThemeProvider } from "styled-bootstrap-grid";
import { Helmet } from "react-helmet";

import GlobalStyle from "theme/GlobalStyle";
import { theme } from "theme/MainTheme";

const gridTheme = {
  container: {
    maxWidth: {
      xxl: 1600,
      xl: 1600,
      lg: 1600,
      md: 1600,
      sm: 1600,
      xs: 1600,
    },
  },
};

const Wrapper = styled.div`
  overflow-x: hidden;
`;

const MainLayout = ({ children, themeSecondaryColor }) => {
  const styledTheme = { ...theme };
  if (themeSecondaryColor) {
    styledTheme.colors.secondary = themeSecondaryColor;
  }

  return (
    <ThemeProvider theme={styledTheme}>
      <Helmet>
        <script type="text/javascript">
          {`
                    (function() { var s = document.createElement("script"),e = document.scripts[document.scripts.length - 1];s.type = "text/javascript";
                    s.async = true;s.src = "//forms.fcc-online.pl/public/widget.js";
                    s.setAttribute('charset', 'utf-8'); s.setAttribute('data-form-id', '41bf4b85-ea91-41d2-b6f7-0d12c3a1e91c');
                    e.parentNode.insertBefore(s, e);})();
                    `}
        </script>
      </Helmet>
      <GlobalStyle />
      <BaseCSS />
      <GridThemeProvider gridTheme={gridTheme}>
        <Wrapper>{children}</Wrapper>
      </GridThemeProvider>
    </ThemeProvider>
  );
};

export default MainLayout;
