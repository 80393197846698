import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import fontEFNWidok from 'fonts/EFNWidok.ttf'

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'EFNWidok';
        font-style: normal;
        font-weight: normal;
        font-display: block;
        src: local('EFNWidok'), url(${fontEFNWidok}) format('truetype');
    }

    ${reset}
  
    body, html {
        font-family: "Open Sans", sans-serif;
    }

    a {
        text-decoration: none;
    }

    *, *::before, *::after {
        box-sizing: border-box;
        border: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-tap-highlight-color: transparent;
        outline: 0 !important;
    }

    @keyframes pulse {
        0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(204,169,44, 0.9);
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 15px rgba(204,169,44, 0);
        }

        100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        }
    }
`;

export default GlobalStyle;
